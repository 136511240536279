import { apiClient } from '@api/client';
import { AuthorizationBoundary } from '@components/AuthorizationBoundary';
import { ErrorPage500 } from '@pages/ErrorPage';
import { useMutation } from '@tanstack/react-query';
import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface AsanaOAuthInnerProps {
  code: string;
  state: string;
  loader: ReactElement;
}

function AsanaOAuthInner({ code, state, loader }: AsanaOAuthInnerProps) {
  const { mutate, isError } = useMutation({
    mutationFn: async () => {
      await apiClient.asana.asanaControllerConnect({
        code,
        state
      });
    },
    onSuccess: () => {
      if (window.opener) {
        window.opener.postMessage('success', 'https://app.asana.com');
        window.close();
      } else {
        window.location.href = 'https://app.asana.com/';
      }
    },
    onError: () => {
      if (window.opener) {
        window.opener.postMessage('error', 'https://app.asana.com');
        window.close();
      }
    }
  });
  useEffect(() => {
    mutate();
  }, [mutate]);
  if (isError) {
    return <ErrorPage500 />;
  }
  return loader;
}

interface AsanaOAuthProps {
  loader: ReactElement;
}

export default function AsanaOAuth({ loader }: AsanaOAuthProps) {
  const [searchParams] = useSearchParams();
  if (searchParams.get('code') || searchParams.get('state')) {
    // Auth0 uses 'code' and 'state' query parameters for its own auth flow
    // We need to rename code and state returned by Asana to avoid conflicts
    searchParams.set('_code', searchParams.get('code') ?? '');
    searchParams.set('_state', searchParams.get('state') ?? '');
    searchParams.delete('code');
    searchParams.delete('state');
    window.location.search = searchParams.toString();
    return loader;
  }
  const code = searchParams.get('_code') ?? '';
  const state = searchParams.get('_state') ?? '';
  return (
    <AuthorizationBoundary
      loader={loader}
      element={<AsanaOAuthInner loader={loader} code={code} state={state} />}
      renderError={() => <ErrorPage500 />}
    />
  );
}
